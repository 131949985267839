import { ReactElement } from 'react';

const CryoFysio = (): ReactElement => (
    <svg width="100%" height="100%" viewBox="0 0 160.747 79.331">
        <defs>
            <linearGradient id="fysio-linear-gradient" x1="-0.032" y1="0.51" x2="5.284" y2="0.424">
                <stop offset="0" stopColor="#0936b2" />
                <stop offset="1" stopColor="#0b1445" />
            </linearGradient>
            <linearGradient
                id="fysio-linear-gradient-2"
                x1="-1.201"
                y1="0.527"
                x2="4.347"
                y2="0.439"
                xlinkHref="#fysio-linear-gradient"
            />
            <linearGradient
                id="fysio-linear-gradient-3"
                x1="-1.794"
                y1="0.538"
                x2="2.951"
                y2="0.451"
                xlinkHref="#fysio-linear-gradient"
            />
            <linearGradient
                id="fysio-linear-gradient-4"
                x1="-2.531"
                y1="0.559"
                x2="1.837"
                y2="0.474"
                xlinkHref="#fysio-linear-gradient"
            />
            <linearGradient
                id="fysio-linear-gradient-5"
                x1="-0.028"
                y1="0.51"
                x2="6.652"
                y2="0.384"
                xlinkHref="#fysio-linear-gradient"
            />
            <linearGradient
                id="fysio-linear-gradient-6"
                x1="-1.327"
                y1="0.518"
                x2="5.724"
                y2="0.431"
                xlinkHref="#fysio-linear-gradient"
            />
            <linearGradient
                id="fysio-linear-gradient-7"
                x1="-1.585"
                y1="0.535"
                x2="3.159"
                y2="0.447"
                xlinkHref="#fysio-linear-gradient"
            />
            <linearGradient
                id="fysio-linear-gradient-8"
                x1="-3.469"
                y1="0.552"
                x2="3.003"
                y2="0.467"
                xlinkHref="#fysio-linear-gradient"
            />
            <linearGradient
                id="fysio-linear-gradient-9"
                x1="-14.017"
                y1="0.565"
                x2="5.436"
                y2="0.478"
                xlinkHref="#fysio-linear-gradient"
            />
            <linearGradient
                id="fysio-linear-gradient-10"
                x1="-3.503"
                y1="0.578"
                x2="0.866"
                y2="0.493"
                xlinkHref="#fysio-linear-gradient"
            />
        </defs>
        <g id="logo_cryo_fysio" transform="translate(-1574.466 -167.88)">
            <path
                id="Path_144"
                data-name="Path 144"
                d="M1599.539,193.731a1.6,1.6,0,0,1,.567.107,1.531,1.531,0,0,1,.542.365l3.161,3.326a13.122,13.122,0,0,1-5.2,4.092,18.2,18.2,0,0,1-7.4,1.38,17.624,17.624,0,0,1-7.017-1.332,15.18,15.18,0,0,1-5.26-3.68,16.2,16.2,0,0,1-3.315-5.567,20.563,20.563,0,0,1-1.155-6.994,19.351,19.351,0,0,1,1.25-7.064,16.4,16.4,0,0,1,3.515-5.555,15.834,15.834,0,0,1,5.425-3.632,18.152,18.152,0,0,1,6.959-1.3,18.635,18.635,0,0,1,3.643.341,17.771,17.771,0,0,1,3.2.944,14.646,14.646,0,0,1,2.737,1.45,14.467,14.467,0,0,1,2.217,1.84l-2.689,3.609a3.431,3.431,0,0,1-.613.6,1.6,1.6,0,0,1-.991.271,1.769,1.769,0,0,1-.8-.189,8.585,8.585,0,0,1-.8-.46l-.932-.59a7.256,7.256,0,0,0-1.2-.589,9.973,9.973,0,0,0-1.627-.46,11.092,11.092,0,0,0-2.182-.189,8.774,8.774,0,0,0-3.62.732,7.876,7.876,0,0,0-2.831,2.134,9.874,9.874,0,0,0-1.84,3.444,15.348,15.348,0,0,0-.649,4.658,14.135,14.135,0,0,0,.72,4.694,10.286,10.286,0,0,0,1.958,3.456,8.3,8.3,0,0,0,2.889,2.135,8.644,8.644,0,0,0,3.538.731,17.1,17.1,0,0,0,1.981-.106,9.032,9.032,0,0,0,1.675-.354,6.78,6.78,0,0,0,1.463-.661,9.135,9.135,0,0,0,1.391-1.049,2.785,2.785,0,0,1,.614-.389A1.591,1.591,0,0,1,1599.539,193.731Z"
                fill="url(#fysio-linear-gradient)"
            />
            <path
                id="Path_145"
                data-name="Path 145"
                d="M1635.415,202.624H1628.2a3.062,3.062,0,0,1-2.878-1.51l-5.708-9.978a2.323,2.323,0,0,0-2.312-1.226h-2.028v12.714H1607.3V168.257h11.181a22.948,22.948,0,0,1,6.356.766,11.934,11.934,0,0,1,4.293,2.135,8.091,8.091,0,0,1,2.418,3.231,10.714,10.714,0,0,1,.755,4.058,10.918,10.918,0,0,1-.448,3.16,9.74,9.74,0,0,1-1.321,2.748,10.061,10.061,0,0,1-2.159,2.253,11.54,11.54,0,0,1-2.96,1.628,7.189,7.189,0,0,1,1.462,1,6.982,6.982,0,0,1,1.227,1.427Zm-16.935-18.21a8.2,8.2,0,0,0,2.771-.412,5.016,5.016,0,0,0,1.875-1.144,4.365,4.365,0,0,0,1.062-1.711,6.565,6.565,0,0,0,.33-2.111,4.424,4.424,0,0,0-1.475-3.538,6.86,6.86,0,0,0-4.563-1.274h-3.208v10.19Z"
                fill="url(#fysio-linear-gradient-2)"
            />
            <path
                id="Path_146"
                data-name="Path 146"
                d="M1652.941,189.556v13.068h-7.973V189.556l-12.454-21.3h7.029a2.549,2.549,0,0,1,1.64.483,3.96,3.96,0,0,1,1,1.239l4.859,10q.612,1.179,1.109,2.205t.873,2.017q.352-1.014.813-2.04t1.073-2.182l4.812-10a4.623,4.623,0,0,1,.4-.614,3.124,3.124,0,0,1,.554-.554,2.739,2.739,0,0,1,.731-.4,2.6,2.6,0,0,1,.909-.153h7.076Z"
                fill="url(#fysio-linear-gradient-3)"
            />
            <path
                id="Path_147"
                data-name="Path 147"
                d="M1699.645,185.428a18.469,18.469,0,0,1-1.286,6.935,16.378,16.378,0,0,1-9.246,9.293,20.518,20.518,0,0,1-14.613,0,16.4,16.4,0,0,1-9.282-9.293,19.353,19.353,0,0,1,0-13.869,16.493,16.493,0,0,1,3.633-5.567,16.785,16.785,0,0,1,5.649-3.7,19.206,19.206,0,0,1,7.312-1.344,18.991,18.991,0,0,1,7.3,1.356,16.863,16.863,0,0,1,5.626,3.715,16.608,16.608,0,0,1,3.62,5.566A18.412,18.412,0,0,1,1699.645,185.428Zm-8.162,0a14.865,14.865,0,0,0-.66-4.587,9.744,9.744,0,0,0-1.9-3.456,8.2,8.2,0,0,0-3.031-2.17,10.437,10.437,0,0,0-4.081-.755,10.58,10.58,0,0,0-4.115.755,8.159,8.159,0,0,0-3.043,2.17,9.714,9.714,0,0,0-1.9,3.456,16.356,16.356,0,0,0,0,9.2,9.7,9.7,0,0,0,1.9,3.455,8.045,8.045,0,0,0,3.043,2.159,10.731,10.731,0,0,0,4.115.743,10.586,10.586,0,0,0,4.081-.743,8.08,8.08,0,0,0,3.031-2.159,9.731,9.731,0,0,0,1.9-3.455A14.953,14.953,0,0,0,1691.483,185.428Z"
                fill="url(#fysio-linear-gradient-4)"
            />
            <path
                id="Path_148"
                data-name="Path 148"
                d="M1598.408,233.6h-8.728v9.388h-5.944V233.6h-8.68v-5.236h8.68v-9.341h5.944v9.341h8.728Z"
                fill="url(#fysio-linear-gradient-5)"
            />
            <path
                id="Path_149"
                data-name="Path 149"
                d="M1611.757,218.6v8.7H1623.5v6.157h-11.747v13.374h-8.019V212.467h22.125V218.6Z"
                fill="url(#fysio-linear-gradient-6)"
            />
            <path
                id="Path_150"
                data-name="Path 150"
                d="M1646.926,233.766v13.068h-7.973V233.766l-12.454-21.3h7.03a2.547,2.547,0,0,1,1.639.483,3.933,3.933,0,0,1,1,1.239l4.86,10q.612,1.179,1.108,2.205t.873,2.017q.352-1.014.814-2.04t1.073-2.182l4.811-10a4.635,4.635,0,0,1,.4-.614,3.087,3.087,0,0,1,.553-.554,2.731,2.731,0,0,1,.732-.4,2.594,2.594,0,0,1,.908-.153h7.076Z"
                fill="url(#fysio-linear-gradient-7)"
            />
            <path
                id="Path_151"
                data-name="Path 151"
                d="M1679.665,219.448a2.99,2.99,0,0,1-.743.85,1.66,1.66,0,0,1-1,.283,2.454,2.454,0,0,1-1.168-.342q-.625-.342-1.415-.767a11.8,11.8,0,0,0-1.8-.766,7.224,7.224,0,0,0-2.312-.343,4.975,4.975,0,0,0-3.338.956,3.254,3.254,0,0,0-1.1,2.583,2.377,2.377,0,0,0,.661,1.722,5.68,5.68,0,0,0,1.734,1.179,18.552,18.552,0,0,0,2.453.908q1.38.414,2.818.92a25.226,25.226,0,0,1,2.819,1.191,9.4,9.4,0,0,1,2.453,1.746,8.121,8.121,0,0,1,1.734,2.583,9.187,9.187,0,0,1,.66,3.668,11.959,11.959,0,0,1-.825,4.458,10.433,10.433,0,0,1-2.394,3.62,11.105,11.105,0,0,1-3.868,2.429,14.474,14.474,0,0,1-5.225.885,16.765,16.765,0,0,1-3.267-.33,19.35,19.35,0,0,1-3.219-.932,18.569,18.569,0,0,1-2.925-1.427,12.354,12.354,0,0,1-2.383-1.84l2.358-3.727a2.382,2.382,0,0,1,.744-.672,1.943,1.943,0,0,1,1-.271,2.689,2.689,0,0,1,1.426.448q.72.448,1.628.991a13.222,13.222,0,0,0,2.087.991,7.842,7.842,0,0,0,2.783.448,5.293,5.293,0,0,0,3.373-.956,3.632,3.632,0,0,0,1.2-3.03,2.865,2.865,0,0,0-.661-1.958,5.1,5.1,0,0,0-1.733-1.25,15.882,15.882,0,0,0-2.441-.873q-1.369-.376-2.808-.837a20.617,20.617,0,0,1-2.807-1.133,8.865,8.865,0,0,1-2.441-1.769,8.276,8.276,0,0,1-1.734-2.736,10.872,10.872,0,0,1-.66-4.045,9.576,9.576,0,0,1,.779-3.774,9.713,9.713,0,0,1,2.288-3.279,11.26,11.26,0,0,1,3.7-2.3,13.693,13.693,0,0,1,5.023-.86,18.84,18.84,0,0,1,3.078.247,16.193,16.193,0,0,1,2.843.731,15.635,15.635,0,0,1,2.512,1.156,11.58,11.58,0,0,1,2.088,1.521Z"
                fill="url(#fysio-linear-gradient-8)"
            />
            <path
                id="Path_152"
                data-name="Path 152"
                d="M1694.831,246.834h-8.019V212.467h8.019Z"
                fill="url(#fysio-linear-gradient-9)"
            />
            <path
                id="Path_153"
                data-name="Path 153"
                d="M1735.213,229.638a18.448,18.448,0,0,1-1.286,6.935,16.378,16.378,0,0,1-9.246,9.293,20.516,20.516,0,0,1-14.612,0,16.4,16.4,0,0,1-9.283-9.293,19.353,19.353,0,0,1,0-13.869,16.525,16.525,0,0,1,3.633-5.567,16.8,16.8,0,0,1,5.65-3.7,19.2,19.2,0,0,1,7.311-1.344,19.005,19.005,0,0,1,7.3,1.355,16.881,16.881,0,0,1,5.626,3.716,16.579,16.579,0,0,1,3.62,5.566A18.39,18.39,0,0,1,1735.213,229.638Zm-8.162,0a14.873,14.873,0,0,0-.66-4.588,9.731,9.731,0,0,0-1.9-3.455,8.2,8.2,0,0,0-3.032-2.17,10.435,10.435,0,0,0-4.081-.755,10.58,10.58,0,0,0-4.115.755,8.167,8.167,0,0,0-3.043,2.17,9.716,9.716,0,0,0-1.9,3.455,16.336,16.336,0,0,0,0,9.2,9.7,9.7,0,0,0,1.9,3.455,8.04,8.04,0,0,0,3.043,2.158,10.712,10.712,0,0,0,4.115.744,10.565,10.565,0,0,0,4.081-.744,8.072,8.072,0,0,0,3.032-2.158,9.714,9.714,0,0,0,1.9-3.455A14.949,14.949,0,0,0,1727.051,229.638Z"
                fill="url(#fysio-linear-gradient-10)"
            />
        </g>
    </svg>
);

export default CryoFysio;
