import { ReactElement } from 'react';

import { IconProps } from '@components/atoms/Icon';

const CryoHair = ({ size, ...rest }: IconProps): ReactElement => (
    <svg width="100%" height="100%" viewBox="0 0 142.254 78.955" {...rest}>
        <g id="logo_cryo_hair" transform="translate(-1574.415 -408.877)">
            <path
                d="M1599.488,434.729a1.625,1.625,0,0,1,.567.106,1.549,1.549,0,0,1,.542.366l3.161,3.326a13.123,13.123,0,0,1-5.2,4.092,18.2,18.2,0,0,1-7.4,1.38,17.624,17.624,0,0,1-7.017-1.332,15.18,15.18,0,0,1-5.26-3.68,16.221,16.221,0,0,1-3.315-5.567,20.575,20.575,0,0,1-1.155-6.994,19.344,19.344,0,0,1,1.25-7.064,16.392,16.392,0,0,1,3.515-5.555,15.83,15.83,0,0,1,5.424-3.633,18.178,18.178,0,0,1,6.959-1.3,18.637,18.637,0,0,1,3.644.342,17.768,17.768,0,0,1,3.2.944,14.587,14.587,0,0,1,2.737,1.45,14.4,14.4,0,0,1,2.216,1.84l-2.688,3.609a3.431,3.431,0,0,1-.613.6,1.6,1.6,0,0,1-.992.271,1.768,1.768,0,0,1-.8-.189,8.6,8.6,0,0,1-.8-.46q-.424-.271-.932-.59a7.215,7.215,0,0,0-1.2-.589,9.974,9.974,0,0,0-1.627-.46,11.092,11.092,0,0,0-2.182-.189,8.79,8.79,0,0,0-3.62.731,7.9,7.9,0,0,0-2.832,2.135,9.889,9.889,0,0,0-1.839,3.444,15.348,15.348,0,0,0-.649,4.658,14.11,14.11,0,0,0,.72,4.694,10.286,10.286,0,0,0,1.958,3.456,8.286,8.286,0,0,0,2.889,2.134,8.628,8.628,0,0,0,3.538.732,16.873,16.873,0,0,0,1.981-.107,8.954,8.954,0,0,0,1.675-.353,6.857,6.857,0,0,0,1.463-.661,9.158,9.158,0,0,0,1.391-1.05,2.854,2.854,0,0,1,.614-.389A1.6,1.6,0,0,1,1599.488,434.729Z"
                fill="#0b1445"
            />
            <path
                d="M1635.364,443.622h-7.218a3.061,3.061,0,0,1-2.877-1.51l-5.708-9.978a2.323,2.323,0,0,0-2.312-1.226h-2.028v12.714h-7.973V409.254h11.181a22.946,22.946,0,0,1,6.356.767,11.934,11.934,0,0,1,4.293,2.135,8.091,8.091,0,0,1,2.418,3.231,10.713,10.713,0,0,1,.755,4.057,10.92,10.92,0,0,1-.448,3.161,9.743,9.743,0,0,1-1.322,2.748,10.049,10.049,0,0,1-2.158,2.252,11.5,11.5,0,0,1-2.96,1.628,7.2,7.2,0,0,1,1.462,1,7.012,7.012,0,0,1,1.227,1.427Zm-16.935-18.21A8.227,8.227,0,0,0,1621.2,425a5.005,5.005,0,0,0,1.875-1.145,4.354,4.354,0,0,0,1.062-1.71,6.591,6.591,0,0,0,.33-2.111,4.423,4.423,0,0,0-1.475-3.538,6.859,6.859,0,0,0-4.563-1.274h-3.208v10.19Z"
                fill="#0b1445"
            />
            <path
                d="M1652.89,430.554v13.068h-7.973V430.554l-12.454-21.3h7.029a2.55,2.55,0,0,1,1.64.484,3.96,3.96,0,0,1,1,1.239l4.859,10q.612,1.179,1.109,2.205t.873,2.017q.352-1.014.813-2.04t1.073-2.182l4.812-10a4.56,4.56,0,0,1,.4-.614,3.089,3.089,0,0,1,.554-.554,2.738,2.738,0,0,1,.731-.4,2.6,2.6,0,0,1,.909-.154h7.076Z"
                fill="#0b1445"
            />
            <path
                d="M1699.594,426.426a18.467,18.467,0,0,1-1.286,6.935,16.386,16.386,0,0,1-9.246,9.293,20.518,20.518,0,0,1-14.613,0,16.409,16.409,0,0,1-9.282-9.293,19.353,19.353,0,0,1,0-13.869,16.494,16.494,0,0,1,3.633-5.567,16.784,16.784,0,0,1,5.649-3.7,19.207,19.207,0,0,1,7.312-1.345,19.008,19.008,0,0,1,7.3,1.356,16.865,16.865,0,0,1,5.626,3.716,16.608,16.608,0,0,1,3.62,5.566A18.409,18.409,0,0,1,1699.594,426.426Zm-8.162,0a14.843,14.843,0,0,0-.661-4.588,9.731,9.731,0,0,0-1.9-3.455,8.186,8.186,0,0,0-3.031-2.17,10.441,10.441,0,0,0-4.081-.755,10.579,10.579,0,0,0-4.115.755,8.148,8.148,0,0,0-3.043,2.17,9.719,9.719,0,0,0-1.9,3.455,16.359,16.359,0,0,0,0,9.2,9.7,9.7,0,0,0,1.9,3.455,8.032,8.032,0,0,0,3.043,2.158,10.712,10.712,0,0,0,4.115.744,10.571,10.571,0,0,0,4.081-.744,8.068,8.068,0,0,0,3.031-2.158,9.714,9.714,0,0,0,1.9-3.455A14.919,14.919,0,0,0,1691.432,426.426Z"
                fill="#0b1445"
            />
            <path
                d="M1598.356,474.6h-8.727v9.388h-5.944V474.6h-8.68v-5.236h8.68v-9.341h5.944v9.341h8.727Z"
                fill="#0b1445"
            />
            <path
                d="M1633.689,453.464v34.368h-8.019V473.3h-13.964v14.53h-8.019V453.464h8.019V467.83h13.964V453.464Z"
                fill="#0b1445"
            />
            <path
                d="M1671.17,487.832h-6.18a2.7,2.7,0,0,1-1.675-.484,3.062,3.062,0,0,1-.966-1.238l-2.03-5.991h-13.067l-2.028,5.991a2.872,2.872,0,0,1-.932,1.191,2.6,2.6,0,0,1-1.663.531H1636.4l13.3-34.368h8.162ZM1658.5,474.693l-3.185-9.411q-.354-.9-.755-2.111t-.8-2.63q-.355,1.439-.755,2.665t-.755,2.123l-3.161,9.364Z"
                fill="#0b1445"
            />
            <path d="M1681.9,487.832h-8.02V453.464h8.02Z" fill="#0b1445" />
            <path
                d="M1716.669,487.832h-7.218a3.06,3.06,0,0,1-2.877-1.51l-5.708-9.978a2.32,2.32,0,0,0-2.311-1.226h-2.03v12.714h-7.972V453.464h11.18a22.954,22.954,0,0,1,6.358.767,11.95,11.95,0,0,1,4.293,2.135,8.083,8.083,0,0,1,2.417,3.231,10.715,10.715,0,0,1,.755,4.057,10.886,10.886,0,0,1-.449,3.161,9.741,9.741,0,0,1-1.32,2.748,10.1,10.1,0,0,1-2.158,2.252,11.518,11.518,0,0,1-2.961,1.628,7.173,7.173,0,0,1,1.463,1,7.007,7.007,0,0,1,1.226,1.427Zm-16.936-18.21a8.212,8.212,0,0,0,2.772-.413,4.993,4.993,0,0,0,1.875-1.144,4.342,4.342,0,0,0,1.061-1.71,6.538,6.538,0,0,0,.331-2.111,4.428,4.428,0,0,0-1.474-3.538,6.867,6.867,0,0,0-4.565-1.274h-3.208v10.19Z"
                fill="#0b1445"
            />
        </g>
    </svg>
);

export default CryoHair;
