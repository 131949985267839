import { ReactElement } from 'react';

import { IconProps } from '@components/atoms/Icon';

const CryoDerma = ({ size, ...rest }: IconProps): ReactElement => (
    <svg width="100%" height="100%" viewBox="0 0 199.335 78.954" {...rest}>
        <defs>
            <linearGradient
                id="linear-gradient"
                x1="-0.242"
                y1="0.5"
                x2="7.29"
                y2="0.5"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stopColor="#f3dfc9" />
                <stop offset="1" stopColor="#aa886e" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-2"
                x1="-1.42"
                y1="0.5"
                x2="6.44"
                y2="0.5"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-3"
                x1="-1.981"
                y1="0.5"
                x2="4.74"
                y2="0.5"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-4"
                x1="-2.704"
                y1="0.5"
                x2="3.484"
                y2="0.5"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-5"
                x1="-0.329"
                y1="0.5"
                x2="9.135"
                y2="0.5"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-6"
                x1="-1.166"
                y1="0.5"
                x2="5.921"
                y2="0.5"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-7"
                x1="-3.267"
                y1="0.5"
                x2="6.722"
                y2="0.5"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-8"
                x1="-3.532"
                y1="0.5"
                x2="4.328"
                y2="0.5"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-9"
                x1="-3.373"
                y1="0.5"
                x2="2.347"
                y2="0.5"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-10"
                x1="-4.938"
                y1="0.5"
                x2="1.419"
                y2="0.5"
                xlinkHref="#linear-gradient"
            />
        </defs>
        <g id="logo_cryo_derma" transform="translate(-1579.102 -587.877)">
            <path
                id="Path_134"
                data-name="Path 134"
                d="M1604.175,613.729a1.614,1.614,0,0,1,.566.106,1.525,1.525,0,0,1,.542.365l3.162,3.326a13.119,13.119,0,0,1-5.2,4.093,18.2,18.2,0,0,1-7.394,1.38,17.615,17.615,0,0,1-7.018-1.333,15.173,15.173,0,0,1-5.259-3.68,16.208,16.208,0,0,1-3.315-5.566,20.574,20.574,0,0,1-1.155-6.994,19.35,19.35,0,0,1,1.25-7.065,16.4,16.4,0,0,1,3.514-5.554,15.831,15.831,0,0,1,5.425-3.633,18.178,18.178,0,0,1,6.959-1.3,18.57,18.57,0,0,1,3.644.342,17.879,17.879,0,0,1,3.2.943,14.617,14.617,0,0,1,2.736,1.451,14.417,14.417,0,0,1,2.217,1.84l-2.688,3.609a3.432,3.432,0,0,1-.614.6,1.589,1.589,0,0,1-.991.272,1.764,1.764,0,0,1-.8-.189,8.833,8.833,0,0,1-.8-.46q-.424-.272-.932-.59a7.214,7.214,0,0,0-1.2-.59,10.073,10.073,0,0,0-1.627-.46,11.187,11.187,0,0,0-2.183-.188,8.8,8.8,0,0,0-3.62.731,7.906,7.906,0,0,0-2.831,2.135,9.882,9.882,0,0,0-1.84,3.444,15.379,15.379,0,0,0-.648,4.658,14.11,14.11,0,0,0,.72,4.694,10.272,10.272,0,0,0,1.958,3.455,8.282,8.282,0,0,0,2.888,2.135,8.635,8.635,0,0,0,3.538.731,16.9,16.9,0,0,0,1.982-.106,8.958,8.958,0,0,0,1.675-.354,6.806,6.806,0,0,0,1.463-.66,9.155,9.155,0,0,0,1.391-1.05,2.8,2.8,0,0,1,.613-.389A1.612,1.612,0,0,1,1604.175,613.729Z"
                fill="url(#linear-gradient)"
            />
            <path
                id="Path_135"
                data-name="Path 135"
                d="M1640.051,622.621h-7.218a3.062,3.062,0,0,1-2.878-1.51l-5.708-9.977a2.319,2.319,0,0,0-2.311-1.227h-2.029v12.714h-7.972V588.254h11.18a22.948,22.948,0,0,1,6.357.767,11.948,11.948,0,0,1,4.293,2.134,8.1,8.1,0,0,1,2.418,3.232,10.709,10.709,0,0,1,.755,4.057,10.941,10.941,0,0,1-.448,3.16,9.736,9.736,0,0,1-1.322,2.749,10.074,10.074,0,0,1-2.158,2.252,11.5,11.5,0,0,1-2.96,1.628,7.232,7.232,0,0,1,1.462,1,7.068,7.068,0,0,1,1.227,1.427Zm-16.936-18.209a8.212,8.212,0,0,0,2.772-.413,4.99,4.99,0,0,0,1.875-1.144,4.365,4.365,0,0,0,1.062-1.71,6.6,6.6,0,0,0,.33-2.111,4.426,4.426,0,0,0-1.475-3.538,6.855,6.855,0,0,0-4.564-1.274h-3.208v10.19Z"
                fill="url(#linear-gradient-2)"
            />
            <path
                id="Path_136"
                data-name="Path 136"
                d="M1657.576,609.554v13.067H1649.6V609.554l-12.454-21.3h7.029a2.553,2.553,0,0,1,1.64.484,3.96,3.96,0,0,1,1,1.239l4.859,10q.612,1.179,1.108,2.2t.873,2.016q.354-1.014.814-2.04t1.073-2.181l4.812-10a4.5,4.5,0,0,1,.4-.614,3.092,3.092,0,0,1,.554-.554,2.77,2.77,0,0,1,.731-.4,2.622,2.622,0,0,1,.908-.153h7.077Z"
                fill="url(#linear-gradient-3)"
            />
            <path
                id="Path_137"
                data-name="Path 137"
                d="M1704.281,605.426a18.439,18.439,0,0,1-1.287,6.934,16.383,16.383,0,0,1-9.246,9.294,20.515,20.515,0,0,1-14.612,0,16.406,16.406,0,0,1-9.282-9.294,19.353,19.353,0,0,1,0-13.869,16.49,16.49,0,0,1,3.633-5.566,16.784,16.784,0,0,1,5.649-3.7,19.207,19.207,0,0,1,7.312-1.345,18.985,18.985,0,0,1,7.3,1.356,16.859,16.859,0,0,1,5.626,3.715,16.593,16.593,0,0,1,3.62,5.567A18.383,18.383,0,0,1,1704.281,605.426Zm-8.163,0a14.839,14.839,0,0,0-.66-4.588,9.724,9.724,0,0,0-1.9-3.455,8.186,8.186,0,0,0-3.031-2.17,10.442,10.442,0,0,0-4.081-.755,10.585,10.585,0,0,0-4.116.755,8.165,8.165,0,0,0-3.043,2.17,9.709,9.709,0,0,0-1.9,3.455,16.356,16.356,0,0,0,0,9.2,9.7,9.7,0,0,0,1.9,3.456,8.048,8.048,0,0,0,3.043,2.158,10.736,10.736,0,0,0,4.116.744,10.59,10.59,0,0,0,4.081-.744,8.068,8.068,0,0,0,3.031-2.158,9.72,9.72,0,0,0,1.9-3.456A14.909,14.909,0,0,0,1696.118,605.426Z"
                fill="url(#linear-gradient-4)"
            />
            <path
                id="Path_138"
                data-name="Path 138"
                d="M1603.043,653.6h-8.727v9.387h-5.945V653.6h-8.679v-5.237h8.679v-9.341h5.945v9.341h8.727Z"
                fill="url(#linear-gradient-5)"
            />
            <path
                id="Path_139"
                data-name="Path 139"
                d="M1639.556,649.636a18.043,18.043,0,0,1-1.285,6.876,16.115,16.115,0,0,1-3.621,5.449,16.439,16.439,0,0,1-5.626,3.585,19.908,19.908,0,0,1-7.3,1.285h-13.351V632.464h13.351a19.743,19.743,0,0,1,7.3,1.3,16.665,16.665,0,0,1,5.626,3.585,15.987,15.987,0,0,1,3.621,5.437A17.975,17.975,0,0,1,1639.556,649.636Zm-8.161,0a14.986,14.986,0,0,0-.661-4.6,9.823,9.823,0,0,0-1.9-3.478,8.248,8.248,0,0,0-3.031-2.2,10.307,10.307,0,0,0-4.08-.766h-5.331v22.1h5.331a10.289,10.289,0,0,0,4.08-.766,8.245,8.245,0,0,0,3.031-2.194,9.827,9.827,0,0,0,1.9-3.479A15.061,15.061,0,0,0,1631.4,649.636Z"
                fill="url(#linear-gradient-6)"
            />
            <path
                id="Path_140"
                data-name="Path 140"
                d="M1652.292,638.6v8.02h10.8v5.9h-10.8V660.7H1666.4v6.133h-22.125V632.464H1666.4V638.6Z"
                fill="url(#linear-gradient-7)"
            />
            <path
                id="Path_141"
                data-name="Path 141"
                d="M1699.42,666.831H1692.2a3.063,3.063,0,0,1-2.878-1.51l-5.708-9.977a2.318,2.318,0,0,0-2.311-1.227h-2.028v12.714H1671.3V632.464h11.181a22.984,22.984,0,0,1,6.356.766,11.966,11.966,0,0,1,4.293,2.135,8.1,8.1,0,0,1,2.418,3.232,10.709,10.709,0,0,1,.755,4.057,10.908,10.908,0,0,1-.448,3.16,9.735,9.735,0,0,1-1.322,2.749,10.051,10.051,0,0,1-2.158,2.252,11.5,11.5,0,0,1-2.96,1.628,7.23,7.23,0,0,1,1.462,1,7.066,7.066,0,0,1,1.227,1.427Zm-16.935-18.209a8.2,8.2,0,0,0,2.771-.413,4.99,4.99,0,0,0,1.875-1.144,4.362,4.362,0,0,0,1.062-1.711,6.585,6.585,0,0,0,.33-2.11,4.423,4.423,0,0,0-1.475-3.538,6.854,6.854,0,0,0-4.563-1.274h-3.208v10.19Z"
                fill="url(#linear-gradient-8)"
            />
            <path
                id="Path_142"
                data-name="Path 142"
                d="M1740.956,632.464v34.367h-7.028V647.065q0-.684.035-1.486t.13-1.628l-9.129,17.667a2.99,2.99,0,0,1-1.143,1.25,3.169,3.169,0,0,1-1.64.425H1721.1a3.169,3.169,0,0,1-1.64-.425,2.99,2.99,0,0,1-1.143-1.25l-9.128-17.714c.047.567.086,1.12.117,1.663s.048,1.042.048,1.5v19.766h-7.029V632.464h6.086c.345,0,.648.009.907.024a2.318,2.318,0,0,1,.7.142,1.579,1.579,0,0,1,.555.365,2.9,2.9,0,0,1,.483.7l8.845,17.432q.471.9.908,1.863t.838,1.982q.4-1.038.837-2.029t.931-1.886l8.823-17.362a2.877,2.877,0,0,1,.483-.7,1.577,1.577,0,0,1,.554-.365,2.318,2.318,0,0,1,.7-.142c.26-.015.562-.024.908-.024Z"
                fill="url(#linear-gradient-9)"
            />
            <path
                id="Path_143"
                data-name="Path 143"
                d="M1778.437,666.831h-6.18a2.7,2.7,0,0,1-1.675-.483,3.067,3.067,0,0,1-.967-1.239l-2.029-5.991h-13.067l-2.029,5.991a2.864,2.864,0,0,1-.931,1.192,2.6,2.6,0,0,1-1.663.53h-6.227l13.3-34.367h8.162Zm-12.666-13.138-3.185-9.412c-.236-.6-.487-1.3-.755-2.11s-.535-1.687-.8-2.63q-.356,1.438-.755,2.665t-.755,2.123l-3.161,9.364Z"
                fill="url(#linear-gradient-10)"
            />
        </g>
    </svg>
);

export default CryoDerma;
